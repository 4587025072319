import { Button, Form, InputNumber, Typography } from "antd"
import React, { useEffect, useState } from "react"
import { onKeyPressNumber } from "../../../customUtil/global"
import downIcon from "../../../images/downIcon.svg"
import upIcon from "../../../images/upIcon.svg"
import "./style.scss"
import {
  updateCollectionsByStore,
  updateCollectionsFeeDefault,
  updateCollectionsFeeDefaultDifference,
} from "../../../services/user"
import {
  openNotificationChangeSuccess,
  openNotificationFail,
} from "../../../components/notification"
import { navigate } from "gatsby"

const { Text } = Typography

const HeaderTab = (props) => {
  const [form] = Form.useForm()
  const { listColections, type } = props
  const [isLoading, setIsLoading] = useState(false)

  const [maxNumberCanSet, setMaxNumberCanSet] = useState(null)

  const handleUp = () => {
    const number = form.getFieldValue("tax")
    if (Number(number)) {
      if (type == "wholesale" && Number(number) == maxNumberCanSet) {
        return
      }
      form.setFieldsValue({
        tax: Number(number) + 1,
      })
    } else {
      form.setFieldsValue({
        tax: 1,
      })
    }
  }

  const handleDown = () => {
    const number = form.getFieldValue("tax")
    if (Number(number) && Number(number) > 0) {
      form.setFieldsValue({
        tax: Number(number) - 1,
      })
    }
  }

  const onFinish = async (values) => {
    console.log("vvvvvv", values)
    if (props.storeId && Number(values?.tax) >= 0) {
      const collectionIds = []
      listColections?.forEach((ele) => {
        collectionIds.push(ele.id)
      })
      const { response } =
        props.storeId === "all"
          ? await updateCollectionsFeeDefaultDifference({
              collectionIds: collectionIds,
              type: type == "wholesale" ? "retail" : "wholesale",
              value: Number(values?.tax),
            })
          : await updateCollectionsFeeDefaultDifference({
              storeId: props.storeId,
              collectionIds: collectionIds,
              type: type == "wholesale" ? "retail" : "wholesale",
              value: Number(values?.tax),
            })
      if (response.status == 200) {
        setIsLoading(false)

        openNotificationChangeSuccess("正常に更新されました。", "", "#389e0d")
        if (props.type == "retail") {
          props.setActiveKey?.("1")
        } else {
          props.setActiveKey?.("2")
        }
      } else {
        setIsLoading(false)

        openNotificationFail(
          "ただいま、サーバが混み合っています。",
          "しばらくしてから再度操作してください。",
          "#f81d22"
        )
      }
    }
  }

  console.log("listColections", listColections)
  console.log("maxNumber", maxNumberCanSet)

  const checkNumber = (_, value) => {
    if (value && type == "retail") {
      if (value > maxNumberCanSet) {
        return Promise.reject(
          new Error(`${maxNumberCanSet}未満入力してください。`)
        )
      } else {
        return Promise.resolve()
      }
    }
  }

  useEffect(() => {
    if (listColections) {
      form?.resetFields()
      let numberColectionsHaveCustom = 0
      let numberColectionsMinInHaveCustom

      listColections?.forEach((element) => {
        if (element?.ApplicationFee?.length > 0) {
          numberColectionsHaveCustom++
          if (
            !numberColectionsMinInHaveCustom &&
            numberColectionsMinInHaveCustom !== 0
          ) {
            numberColectionsMinInHaveCustom = Number(
              element?.ApplicationFee?.[0]?.fee
            )
          } else if (
            numberColectionsMinInHaveCustom >
            Number(element?.ApplicationFee?.[0]?.fee)
          ) {
            numberColectionsMinInHaveCustom = Number(
              element?.ApplicationFee?.[0]?.fee
            )
          }
        }
      })
      console.log("numberColections", numberColectionsHaveCustom)
      console.log(
        "numberColectionsMinInHaveCustom",
        numberColectionsMinInHaveCustom
      )
      if (numberColectionsHaveCustom == listColections.length) {
        setMaxNumberCanSet(numberColectionsMinInHaveCustom)
      } else {
        if (type == "wholesale") {
          if (0 < numberColectionsHaveCustom < listColections.length) {
            if (
              numberColectionsMinInHaveCustom <
              listColections?.[0]?.application_fee_wholesale_default
            ) {
              setMaxNumberCanSet(numberColectionsMinInHaveCustom)
            } else {
              setMaxNumberCanSet(
                listColections?.[0]?.application_fee_wholesale_default
              )
            }
          } else {
            setMaxNumberCanSet(
              listColections?.[0]?.application_fee_wholesale_default
            )
          }
        } else {
          if (0 < numberColectionsHaveCustom < listColections.length) {
            if (
              numberColectionsMinInHaveCustom <
              listColections?.[0]?.application_fee_retail_default
            ) {
              setMaxNumberCanSet(numberColectionsMinInHaveCustom)
            } else {
              setMaxNumberCanSet(
                listColections?.[0]?.application_fee_retail_default
              )
            }
          } else {
            setMaxNumberCanSet(
              listColections?.[0]?.application_fee_retail_default
            )
          }
        }
      }
    }
  }, [listColections])

  return (
    <Form form={form} onFinish={onFinish}>
      <div>
        <div className="customForm flex items-center justify-start font-notoSansJP p-[30px_20px_20px_20px]">
          <div className="flex flex-row items-center ">
            {" "}
            <Text className="text-[13px] !text-black-5 mr-[6px]">
              {props.type == "wholesale"
                ? "飲食店・小売店向け手数料に"
                : "生産者・メーカー・卸向け手数料に"}
            </Text>
            <div className="flex">
              {maxNumberCanSet !== 0 && (
                <div className="flex flex-col items-center w-[18px] h-8 child:flex-1 bg-[#F8E6E9] pt-[5px]">
                  <div
                    className="border-b border-b-[rgba(255,255,255,0.4)]  cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault()

                      handleUp()
                    }}
                  >
                    <img src={upIcon} alt="" />
                  </div>
                  <div
                    onClick={(e) => {
                      e.preventDefault()
                      handleDown()
                    }}
                    className=" cursor-pointer"
                  >
                    <img src={downIcon} alt="" />
                  </div>
                </div>
              )}
              <Form.Item
                name="tax"
                rules={
                  type == "retail"
                    ? [
                        {
                          validator: checkNumber,
                        },
                      ]
                    : null
                }
              >
                <InputNumber
                  type="number"
                  disabled={!maxNumberCanSet}
                  className={`!w-12 !h-8   rounded-[0px_2px_2px_0px] outline-none text-center ${
                    !maxNumberCanSet && "cursor-not-allowed"
                  }`}
                  onKeyPress={onKeyPressNumber}
                />
              </Form.Item>
            </div>
            <Text className="text-[13px] !text-black-5 mx-2">
              % した手数料を反映する
            </Text>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={!maxNumberCanSet}
              className="!text-sm !text-white !font-bold !w-[76px] !h-[25px] !flex items-center justify-center"
            >
              反映する
            </Button>
          </div>
          <div className="flex flex-1 justify-end">
            <Button
              type="primary"
              className="!text-sm  !font-bold  !h-[25px] !flex items-center justify-center"
              onClick={() => {
                navigate(
                  `/super-sales-commission-setting-company/setting/for-product?storeId=${props.storeId}`
                )
              }}
            >
              商品ごとに手数料設定
            </Button>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default HeaderTab
