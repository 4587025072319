// eslint-disable-next-line react/display-name
import { Button, Modal, Row, Col, Checkbox, Divider } from "antd"
import React, { useEffect, useState } from "react"

const ModalSelectCollectionMedium = ({
  record,
  listDataSourceOfTable,
  setListDataSourceOfTable,
  type,
}) => {
  const [visible, setVisible] = useState(false)
  const [collectionChecked, setCollectionChecked] = useState(null)
  const handleCancel = () => {
    setVisible(false)
  }

  const onChange = (checkedValues) => {
    // console.log("checked = ", checkedValues)
    setCollectionChecked(checkedValues)
  }

  const handleAddToTable = () => {
    console.log("hihihi")
    console.log("record", record)
    console.log("collectionChecked", collectionChecked)

    const indexOfRecordInListDataSourceOfTable = listDataSourceOfTable
      .map(function (e) {
        return e.id
      })
      .indexOf(record.id)
    // console.log(
    //   "indexOfRecordInListDataSourceOfTable",
    //   indexOfRecordInListDataSourceOfTable
    // )
    const lengthRowHaveSameId = listDataSourceOfTable.filter(
      (el) => el.id == record.id
    ).length
    // eslint-disable-next-line no-empty
    if (collectionChecked.length == record?.items?.length) {
      const collectionDontHaveInListData = []
      collectionChecked.forEach((element) => {
        if (
          listDataSourceOfTable.find((eleFind) => eleFind.idMedium == element)
        ) {
          return
        } else {
          collectionDontHaveInListData.push(element)
        }
      })
      console.log("collectionDontHaveInListData", collectionDontHaveInListData)
      if (collectionDontHaveInListData?.length > 0) {
        const listRecordBefore = listDataSourceOfTable.filter(
          (el) => el.id == record.id && el.idMedium
        )
        const arrayComplementAfter = collectionDontHaveInListData.map((el) => {
          return {
            ...record,
            titleMedium: record.items.filter((ele) => ele.id == el)[0]?.title,
            idMedium: el,
            metadataMedium: record.items.filter((ele) => ele.id == el)[0]
              ?.metadata,
            is_custom_application_fee_medium:
              type == "wholesale"
                ? record.items.filter((ele) => ele.id == el)[0]
                    ?.is_custom_application_fee_wholesale
                : record.items.filter((ele) => ele.id == el)[0]
                    ?.is_custom_application_fee_retail,
            application_fee_default_medium:
              type == "wholesale"
                ? record.items.filter((ele) => ele.id == el)[0]
                    ?.application_fee_wholesale_default
                : record.items.filter((ele) => ele.id == el)[0]
                    ?.application_fee_retail_default,
          }
        })
        const arrayComplement = [...listRecordBefore, ...arrayComplementAfter]
        const listDataSourceOfTableCopy = [...listDataSourceOfTable]
        listDataSourceOfTableCopy.splice(
          indexOfRecordInListDataSourceOfTable,
          lengthRowHaveSameId,
          ...arrayComplement
        )
        console.log("listDataSourceOfTableCopy", listDataSourceOfTableCopy)
        setListDataSourceOfTable(listDataSourceOfTableCopy)
      }
    }
    if (collectionChecked.length < record?.items?.length) {
      const collectionDontHaveInListData = []
      collectionChecked.forEach((element) => {
        if (
          listDataSourceOfTable.find((eleFind) => eleFind.idMedium == element)
        ) {
          return
        } else {
          collectionDontHaveInListData.push(element)
        }
      })
      let arrayComplement
      const listRecordBeforeHaveInCollectionChecked = listDataSourceOfTable.filter(
        (el) =>
          el.id == record.id &&
          el.idMedium &&
          collectionChecked.filter((element) => element == el.idMedium)
            ?.length > 0
      )
      console.log(
        "listRecordBeforeHaveInCollectionChecked",
        listRecordBeforeHaveInCollectionChecked
      )

      if (collectionDontHaveInListData?.length > 0) {
        const arrayComplementAfter = collectionDontHaveInListData.map((el) => {
          return {
            ...record,
            titleMedium: record.items.filter((ele) => ele.id == el)[0]?.title,
            idMedium: el,
            metadataMedium: record.items.filter((ele) => ele.id == el)[0]
              ?.metadata,
            is_custom_application_fee_medium:
              type == "wholesale"
                ? record.items.filter((ele) => ele.id == el)[0]
                    ?.is_custom_application_fee_wholesale
                : record.items.filter((ele) => ele.id == el)[0]
                    ?.is_custom_application_fee_retail,
            application_fee_default_medium:
              type == "wholesale"
                ? record.items.filter((ele) => ele.id == el)[0]
                    ?.application_fee_wholesale_default
                : record.items.filter((ele) => ele.id == el)[0]
                    ?.application_fee_retail_default,
          }
        })
        arrayComplement = [
          ...listRecordBeforeHaveInCollectionChecked,
          ...arrayComplementAfter,
          record,
        ]
      } else {
        arrayComplement = [...listRecordBeforeHaveInCollectionChecked, record]
      }
      console.log("arrayComplement", arrayComplement)

      const listDataSourceOfTableCopy = [...listDataSourceOfTable]
      listDataSourceOfTableCopy.splice(
        indexOfRecordInListDataSourceOfTable,
        lengthRowHaveSameId,
        ...arrayComplement
      )

      console.log("listDataSourceOfTableCopy", listDataSourceOfTableCopy)
      setListDataSourceOfTable(listDataSourceOfTableCopy)
      setVisible(false)
    }
  }

  useEffect(() => {
    const arrayHaveSameId = listDataSourceOfTable.filter(
      (el) => el.id == record.id
    )
    const arrayMediumCollectionSelected = arrayHaveSameId.filter(
      (el) => el.idMedium
    )
    const arrayChecked = []
    arrayMediumCollectionSelected.map((el) => {
      arrayChecked.push(el.idMedium)
    })
    if (arrayChecked.length > 0) {
      setCollectionChecked([...new Set(arrayChecked)])
    }
  }, [listDataSourceOfTable])

  return (
    <>
      <Button
        style={{
          height: 25,
          fontSize: 12,
          background: "#ECB427",
          display: "flex",
          alignItems: "center",
          color: "#fff",
        }}
        onClick={() => {
          setVisible(true)
          console.log("record", record)
          console.log("listDataSourceOfTable", listDataSourceOfTable)
        }}
      >
        {"＋ カテゴリ設定"}
      </Button>
      <Modal
        title={
          <div style={{ fontWeight: 700, color: "#E60020" }}>
            {`${record.title}の中カテゴリ`}
          </div>
        }
        onCancel={handleCancel}
        footer={false}
        // closable={false}

        width={"50%"}
        visible={visible}
        // bodyStyle={{ borderRadius: "40px" }}
      >
        <div style={{ padding: "20px 5% 10px 5%" }}>
          <Checkbox.Group
            style={{
              width: "100%",
            }}
            value={collectionChecked}
            onChange={onChange}
          >
            <Row>
              {record.items?.map((el) => {
                return (
                  <Col span={8}>
                    <Checkbox value={el.id}>{el.title}</Checkbox>
                  </Col>
                )
              })}
            </Row>
          </Checkbox.Group>
        </div>
        <Divider />
        <div style={{ textAlign: "center" }}>
          <Button type="primary" onClick={handleAddToTable}>
            {"このカテゴリを個別に設定する"}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ModalSelectCollectionMedium
