import React, { useState } from "react"
import CompanyInforContact from "./CompanyInforContact"
import "./style.scss"

const TableDetail = ({ dataInfo }) => {
  return (
    <div>
      <div className="backgroundWhite table-detail border-b  text-[#000]">
        <div className="relative">
          <CompanyInforContact title="企業情報" data={dataInfo} />
          {/* <div className={!toggleCollapse && "blur"}></div> */}
        </div>
      </div>
      {/* <div className="text-center mt-4">
        <Button danger onClick={() => setToggleCollapse(!toggleCollapse)}>
          詳細を見る
        </Button>
      </div> */}
    </div>
  )
}

export default TableDetail
