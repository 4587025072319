import React, { useEffect, useState } from "react"
import { Button, Input, Space, Popconfirm } from "antd"
import {
  openNotificationChangeSuccess,
  openNotificationFail,
} from "../../../../components/notification"
import { onKeyPressNumber } from "../../../../customUtil/global"
import { deleteTaxFee, updateTaxFee } from "../../../../services/taxFee"
const UpdateFeeMedium = ({
  record,
  setListDataSourceOfTable,
  listDataSourceOfTable,
  type,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [numberBefore, setNumberBefore] = useState(null)
  const [numberAfter, setNumberAfter] = useState(null)

  const handleUpdateCollectionMedium = async () => {
    setIsLoading(true)
    const { response } = await updateTaxFee({
      fee: Number(numberAfter),
      type: "applicationFee",
      applicationType: type,
      collectionId: record.idMedium,
    })
    if (response.status == 200) {
      setIsLoading(false)
      setNumberBefore(numberAfter)
      const indexOfRecord = listDataSourceOfTable
        .map(function (e) {
          return e.idMedium
        })
        .indexOf(record.idMedium)

      const listDataSourceOfTableUpdate = listDataSourceOfTable.map((ele) => {
        if (ele.idMedium == record.idMedium) {
          return {
            ...ele,
            is_custom_application_fee_medium: true,
            application_fee_default_medium: numberAfter,
            application_fee_default_min: ele.is_custom_application_fee_min
              ? ele.application_fee_default_min
              : numberAfter,
          }
        }
        return ele
      })

      const indexOfElementChange = record.items
        .map(function (e) {
          return e.id
        })
        .indexOf(record.idMedium)

      const listItemsChange = record.items?.map((el, index) => {
        if (index == indexOfElementChange) {
          const arrayOtherProductCollectionChild = el.other_product_collection?.map(
            (element) => {
              if (type == "wholesale") {
                if (element.is_custom_application_fee_wholesale) {
                  return element
                } else {
                  return {
                    ...element,
                    application_fee_wholesale_default: numberAfter,
                  }
                }
              } else {
                if (element.is_custom_application_fee_retail) {
                  return element
                } else {
                  return {
                    ...element,
                    application_fee_retail_default: numberAfter,
                  }
                }
              }
            }
          )
          if (type == "wholesale") {
            return {
              id: record.idMedium,
              title: record.titleMedium,
              is_custom_application_fee_wholesale: true,
              application_fee_wholesale_default: numberAfter,
              other_product_collection: arrayOtherProductCollectionChild,
            }
          } else {
            return {
              id: record.idMedium,
              title: record.titleMedium,
              is_custom_application_fee_retail: true,
              application_fee_retail_default: numberAfter,
              other_product_collection: arrayOtherProductCollectionChild,
            }
          }
        } else {
          return el
        }
      })

      const listDataSourceOfTableUpdateChange = listDataSourceOfTableUpdate.map(
        (el) => {
          if (el.id == record.id) {
            return { ...el, items: listItemsChange }
          } else {
            return el
          }
        }
      )

      setListDataSourceOfTable([...listDataSourceOfTableUpdateChange])
      openNotificationChangeSuccess("正常に更新されました。", "", "#389e0d")
    } else {
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "#f81d22"
      )
    }
  }
  useEffect(() => {
    setNumberBefore(record.application_fee_default_medium)
    setNumberAfter(record.application_fee_default_medium)
  }, [record])
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Space size={5} direction="horizontal">
        <Input
          value={numberAfter}
          type="number"
          style={{ width: 46, height: 25 }}
          onKeyPress={onKeyPressNumber}
          onChange={(e) => {
            if (e.target.value) {
              setNumberAfter(Number(e.target.value))
              return
            }
            setNumberAfter(0)
          }}
        />
        <div>{"%"}</div>
      </Space>
      <Space
        size={5}
        style={{
          padding: "0px 0px 0px 1rem",
          borderLeft: "1px solid #CCCCCC",
        }}
      >
        <Button
          style={{ height: 25, fontSize: 10 }}
          danger
          disabled={numberAfter == numberBefore}
          loading={isLoading}
          onClick={handleUpdateCollectionMedium}
        >
          {"編集"}
        </Button>
        {record?.is_custom_application_fee_medium ? (
          <Popconfirm
            placement="bottomLeft"
            title={
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {`削除ボタンを押下した時、すべて製品の ${record.title} の手数料は  ${record.application_fee_default}%になります。本当に実行しますか？ `}
              </div>
            }
            onConfirm={async () => {
              setIsLoadingDelete(true)
              const { response } = await deleteTaxFee({
                type: "applicationFee",
                collectionId: record.idMedium,
                applicationType: type,
              })
              if (response.status == 200) {
                setIsLoadingDelete(false)
                const indexOfRecord = listDataSourceOfTable
                  .map(function (e) {
                    return e.idMedium
                  })
                  .indexOf(record.idMedium)

                const listDataSourceOfTableByRecordID = listDataSourceOfTable.filter(
                  (el) => el.id == record.id
                )
                const listDataSourceOfTableByRecordIdMedium = listDataSourceOfTableByRecordID.filter(
                  (el) => el.idMedium
                )

                const listIdMediumHaveInList = [
                  ...new Set(
                    listDataSourceOfTableByRecordIdMedium?.map(
                      (el) => el.idMedium
                    )
                  ),
                ]
                if (listIdMediumHaveInList.length == record.items.length) {
                  const indexOfFisrt = listDataSourceOfTable
                    .map(function (e) {
                      return e.id
                    })
                    .indexOf(record.id)
                  const indexOfElementChange = record.items
                    .map(function (e) {
                      return e.id
                    })
                    .indexOf(record.idMedium)

                  const listItemsChange = record.items?.map((el, index) => {
                    if (index == indexOfElementChange) {
                      const arrayOtherProductCollectionChild = el.other_product_collection?.map(
                        (element) => {
                          if (type == "wholesale") {
                            if (element.is_custom_application_fee_wholesale) {
                              return element
                            } else {
                              return {
                                ...element,
                                application_fee_wholesale_default:
                                  record.application_fee_default,
                              }
                            }
                          } else {
                            if (element.is_custom_application_fee_retail) {
                              return element
                            } else {
                              return {
                                ...element,
                                application_fee_retail_default:
                                  record.application_fee_default,
                              }
                            }
                          }
                        }
                      )
                      return {
                        id: record.idMedium,
                        title: record.titleMedium,
                        is_custom_application_fee: false,
                        application_fee_default: record.application_fee_default,
                        other_product_collection: arrayOtherProductCollectionChild,
                      }
                    } else {
                      return el
                    }
                  })

                  const indexFirstOfRecordMedium = listDataSourceOfTableByRecordIdMedium
                    .map(function (e) {
                      return e.idMedium
                    })
                    .indexOf(record.idMedium)
                  const lengthOfRecordMedium = listDataSourceOfTableByRecordIdMedium.filter(
                    (ele) => ele.idMedium == record.idMedium
                  )?.length
                  listDataSourceOfTableByRecordIdMedium.splice(
                    indexFirstOfRecordMedium,
                    lengthOfRecordMedium
                  )
                  listDataSourceOfTableByRecordIdMedium.map((el) => {
                    return { ...el, items: listItemsChange }
                  })
                  listDataSourceOfTableByRecordIdMedium.push({
                    id: record.id,
                    title: record.title,
                    is_custom_application_fee: record.is_custom_application_fee,
                    application_fee_default: record.application_fee_default,
                    items: listItemsChange,
                  })
                  listDataSourceOfTable.splice(
                    indexOfFisrt,
                    listDataSourceOfTableByRecordID.length,
                    ...listDataSourceOfTableByRecordIdMedium
                  )
                }
                if (listIdMediumHaveInList.length < record.items.length) {
                  const indexOfElementChange = record.items
                    .map(function (e) {
                      return e.id
                    })
                    .indexOf(record.idMedium)

                  const listItemsChange = record.items?.map((el, index) => {
                    if (index == indexOfElementChange) {
                      const arrayOtherProductCollectionChild = el.other_product_collection?.map(
                        (element) => {
                          if (type == "wholesale") {
                            if (element.is_custom_application_fee_wholesale) {
                              return element
                            } else {
                              return {
                                ...element,
                                application_fee_wholesale_default:
                                  record.application_fee_default,
                              }
                            }
                          } else {
                            if (element.is_custom_application_fee_retail) {
                              return element
                            } else {
                              return {
                                ...element,
                                application_fee_retail_default:
                                  record.application_fee_default,
                              }
                            }
                          }
                        }
                      )
                      if (type == "wholesale") {
                        return {
                          id: record.idMedium,
                          title: record.titleMedium,
                          is_custom_application_fee_wholesale: false,
                          application_fee_wholesale_default:
                            record.application_fee_default,
                          other_product_collection: arrayOtherProductCollectionChild,
                        }
                      } else {
                        return {
                          id: record.idMedium,
                          title: record.titleMedium,
                          is_custom_application_fee_retail: false,
                          application_fee_retail_default:
                            record.application_fee_default,
                          other_product_collection: arrayOtherProductCollectionChild,
                        }
                      }
                    } else {
                      return el
                    }
                  })

                  const lengthOfRecordMedium = listDataSourceOfTableByRecordIdMedium.filter(
                    (ele) => ele.idMedium == record.idMedium
                  )?.length
                  listDataSourceOfTable.splice(
                    indexOfRecord,
                    lengthOfRecordMedium
                  )

                  listDataSourceOfTable = listDataSourceOfTable.map((el) => {
                    if (el.id == record.id) {
                      return { ...el, items: listItemsChange }
                    } else {
                      return el
                    }
                  })
                }

                setListDataSourceOfTable([...listDataSourceOfTable])
                openNotificationChangeSuccess(
                  "正常に更新されました。",
                  "",
                  "#389e0d"
                )
              } else {
                openNotificationFail(
                  "ただいま、サーバが混み合っています。",
                  "しばらくしてから再度操作してください。",
                  "#f81d22"
                )
              }
            }}
            okText="削除"
            cancelText="キャンセル"
          >
            <Button
              style={{ height: 25, fontSize: 10 }}
              loading={isLoadingDelete}
            >
              {"削除"}
            </Button>
          </Popconfirm>
        ) : (
          <Button
            style={{ height: 25, fontSize: 10 }}
            onClick={() => {
              const indexOfRecord = listDataSourceOfTable
                .map(function (e) {
                  return e.idMedium
                })
                .indexOf(record.idMedium)

              const listDataSourceOfTableByRecordID = listDataSourceOfTable.filter(
                (el) => el.id == record.id
              )
              const listDataSourceOfTableByRecordIdMedium = listDataSourceOfTableByRecordID.filter(
                (el) => el.idMedium
              )

              const listIdMediumHaveInList = [
                ...new Set(
                  listDataSourceOfTableByRecordIdMedium?.map(
                    (el) => el.idMedium
                  )
                ),
              ]
              if (listIdMediumHaveInList.length == record.items.length) {
                const indexOfFisrt = listDataSourceOfTable
                  .map(function (e) {
                    return e.id
                  })
                  .indexOf(record.id)
                const indexOfElementChange = record.items
                  .map(function (e) {
                    return e.id
                  })
                  .indexOf(record.idMedium)

                const listItemsChange = record.items?.map((el, index) => {
                  if (index == indexOfElementChange) {
                    const arrayOtherProductCollectionChild = el.other_product_collection?.map(
                      (element) => {
                        if (type == "wholesale") {
                          if (element.is_custom_application_fee_wholesale) {
                            return element
                          } else {
                            return {
                              ...element,
                              application_fee_wholesale_default:
                                record.application_fee_default,
                            }
                          }
                        } else {
                          if (element.is_custom_application_fee_retail) {
                            return element
                          } else {
                            return {
                              ...element,
                              application_fee_retail_default:
                                record.application_fee_default,
                            }
                          }
                        }
                      }
                    )
                    if (type == "wholesale") {
                      return {
                        id: record.idMedium,
                        title: record.titleMedium,
                        is_custom_application_fee_wholesale: false,
                        application_fee_wholesale_default:
                          record.application_fee_default,
                        other_product_collection: arrayOtherProductCollectionChild,
                      }
                    } else {
                      return {
                        id: record.idMedium,
                        title: record.titleMedium,
                        is_custom_application_fee_retail: false,
                        application_fee_retail_default:
                          record.application_fee_default,
                        other_product_collection: arrayOtherProductCollectionChild,
                      }
                    }
                  } else {
                    return el
                  }
                })

                const indexFirstOfRecordMedium = listDataSourceOfTableByRecordIdMedium
                  .map(function (e) {
                    return e.idMedium
                  })
                  .indexOf(record.idMedium)
                const lengthOfRecordMedium = listDataSourceOfTableByRecordIdMedium.filter(
                  (ele) => ele.idMedium == record.idMedium
                )?.length
                listDataSourceOfTableByRecordIdMedium.splice(
                  indexFirstOfRecordMedium,
                  lengthOfRecordMedium
                )
                listDataSourceOfTableByRecordIdMedium.map((el) => {
                  return { ...el, items: listItemsChange }
                })
                listDataSourceOfTableByRecordIdMedium.push({
                  id: record.id,
                  title: record.title,
                  is_custom_application_fee: record.is_custom_application_fee,
                  application_fee_default: record.application_fee_default,
                  items: listItemsChange,
                })
                listDataSourceOfTable.splice(
                  indexOfFisrt,
                  listDataSourceOfTableByRecordID.length,
                  ...listDataSourceOfTableByRecordIdMedium
                )
              }
              if (listIdMediumHaveInList.length < record.items.length) {
                const indexOfElementChange = record.items
                  .map(function (e) {
                    return e.id
                  })
                  .indexOf(record.idMedium)

                const listItemsChange = record.items?.map((el, index) => {
                  if (index == indexOfElementChange) {
                    const arrayOtherProductCollectionChild = el.other_product_collection?.map(
                      (element) => {
                        if (type == "wholesale") {
                          if (element.is_custom_application_fee_wholesale) {
                            return element
                          } else {
                            return {
                              ...element,
                              application_fee_wholesale_default:
                                record.application_fee_default,
                            }
                          }
                        } else {
                          if (element.is_custom_application_fee_retail) {
                            return element
                          } else {
                            return {
                              ...element,
                              application_fee_retail_default:
                                record.application_fee_default,
                            }
                          }
                        }
                      }
                    )
                    if (type == "wholesale") {
                      return {
                        id: record.idMedium,
                        title: record.titleMedium,
                        is_custom_application_fee_wholesale: false,
                        application_fee_wholesale_default:
                          record.application_fee_default,
                        other_product_collection: arrayOtherProductCollectionChild,
                      }
                    } else {
                      return {
                        id: record.idMedium,
                        title: record.titleMedium,
                        is_custom_application_fee_retail: false,
                        application_fee_retail_default:
                          record.application_fee_default,
                        other_product_collection: arrayOtherProductCollectionChild,
                      }
                    }
                  } else {
                    return el
                  }
                })

                const lengthOfRecordMedium = listDataSourceOfTableByRecordIdMedium.filter(
                  (ele) => ele.idMedium == record.idMedium
                )?.length
                listDataSourceOfTable.splice(
                  indexOfRecord,
                  lengthOfRecordMedium
                )

                listDataSourceOfTable.map((el) => {
                  if (el.id == record.id) {
                    return { ...el, items: listItemsChange }
                  } else {
                    return el
                  }
                })
              }

              setListDataSourceOfTable([...listDataSourceOfTable])
            }}
          >
            {"削除"}
          </Button>
        )}
      </Space>
    </div>
  )
}

export default UpdateFeeMedium
